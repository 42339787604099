import React from 'react';
import styled, { useTheme } from 'styled-components';
import SectionDescription from './formatting/SectionDescription';
import { device } from '../constants';

interface ContainerProps {
  firstColor: string;
  secondColor: string;
  colorStopValue: string;
}
const Container = styled.section<ContainerProps>`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: ${props => props.theme.paddings.pd * 2.5}px;
  }

  background: linear-gradient(
    to bottom,
    ${props => props.firstColor} 0%,
    ${props => props.firstColor} ${props => props.colorStopValue}%,
    ${props => props.secondColor} ${props => props.colorStopValue}%
  );
`;

interface ColouredPageSectionProps {
  title: string;
  sectionDescription?: string;
  firstColor?: string;
  secondColor?: string;
  colorStopValue?: string;
}

const ColouredPageSection: React.FC<ColouredPageSectionProps> = function (
  props
) {
  const {
    title,
    sectionDescription,
    firstColor,
    secondColor,
    colorStopValue,
  } = props;

  const theme = useTheme();

  return (
    <Container
      firstColor={firstColor ?? theme.colors.coral}
      secondColor={secondColor ?? '#fff'}
      colorStopValue={colorStopValue ?? '50'}
    >
      <h1>{title}</h1>
      <SectionDescription>{sectionDescription}</SectionDescription>
      {props.children}
    </Container>
  );
};

export default ColouredPageSection;
