import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface ButtonProps {
  background: string;
  color: string;
  borderColor: string;
}

const ButtonWrapper = styled.button<ButtonProps>`
  display: inline-block;
  background: ${props => props.background ?? props.theme.colors.purple};
  color: ${props => props.color ?? 'white'};
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  padding: 0.5rem ${props => props.theme.paddings.halfpd}px;
  border: 1px solid ${props => props.borderColor ?? props.theme.colors.purple};
  border-radius: 6px;
  cursor: pointer;

  transition: background ease-in-out 30ms, color ease-in-out 30ms;

  &:hover {
    cursor: pointer;
    color: ${props => props.background ?? props.theme.colors.purple};
    background: ${props => props.color ?? 'white'};
  }
`;

interface Props {
  className?: string;
  buttonType: keyof typeof theme['buttons'];
  background?: string; // override
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const Button: React.FC<Props> = function (props) {
  const {
    className,
    children,
    onClick,
    buttonType,
    background,
    disabled,
  } = props;

  const colors = !theme.buttons[buttonType]
    ? theme.buttons.primary
    : theme.buttons[buttonType];

  return (
    <>
      {props.buttonType && (
        <ButtonWrapper
          className={className}
          onClick={onClick}
          background={background ?? colors.background}
          color={colors.color}
          borderColor={colors.borderColor}
          disabled={disabled}
        >
          {children}
        </ButtonWrapper>
      )}
    </>
  );
};

export default Button;
