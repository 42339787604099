import React, { useCallback, useContext, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import styled from 'styled-components';
import SectionDescription from '../formatting/SectionDescription';
import Input from '../form-elements/Input';
import { device } from '../../constants';
import Button from '../form-elements/Button';
import SiteConfigContext from '../../context/SiteConfigContext';
import MDX from '../../cms/MDX';

const Container = styled.div`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
  }
  background-color: ${props => props.theme.colors.lightGray};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Form = styled.form`
  flex: 1 1 40%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props => props.theme.paddings.halfpd}px;

  & input:nth-child(3) {
    grid-column: 1/2;
  }

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.paddings.halfpd * 1.5}px;

    & input:nth-child(3) {
      grid-column: 1/3;
    }
  }
`;

const Text = styled.div`
  flex: 1 1 100%;
  margin-bottom: ${props => props.theme.paddings.pd}px;
  margin-right: 0;

  @media screen and ${device.laptop} {
    flex: 1 1 40%;
    margin-right: ${props => props.theme.paddings.pd * 2}px;
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled.span`
  padding: 12px 8px;
  background: #f4adad;
  color: #e22424;
`;

const SubscribeForm: React.FC = function () {
  const siteCfg = useContext(SiteConfigContext);

  const { homepage_subscription_form } = siteCfg;
  const {
    title,
    short_description,
    long_description,
    mailto,
  } = homepage_subscription_form;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback<React.MouseEventHandler>(
    e => {
      e.preventDefault();

      async function submit() {
        await firebase.functions().httpsCallable('expressInterest')({
          firstName,
          lastName,
          email,
        });
        setLoading(false);
        setSuccess(true);
      }

      setError(null);
      setLoading(true);
      submit().catch(e => setError(e));
    },
    [firstName, lastName, email]
  );

  return (
    <Container>
      <h1>{title}</h1>
      <SectionDescription>{short_description}</SectionDescription>

      <ContentContainer>
        <Text>
          <MDX>{long_description}</MDX>
        </Text>
        <Form>
          <Input
            type="text"
            placeholder="First name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            marginBottom={0}
          />
          <Input
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            marginBottom={0}
          />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            marginBottom={0}
          />
          <Button
            buttonType="primary"
            onClick={handleSubmit}
            disabled={success || loading}
          >
            {success && 'Enquiry Submitted!'}
            {loading && 'Submitting...'}
            {!success && !loading && 'Make an Enquiry'}
          </Button>
          {error && <ErrorMessage>An error occurred.</ErrorMessage>}
        </Form>
      </ContentContainer>
    </Container>
  );
};

export default SubscribeForm;
