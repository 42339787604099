import React from 'react';
import styled from 'styled-components';
import MDX from '../cms/MDX';
import { device } from '../constants';
import LinkButton from './form-elements/LinkButton';

const Wrapper = styled.div`
  display: grid;
  overflow: hidden;

  grid-template-rows: auto 1fr 2fr 3fr auto;
  row-gap: 16px;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.purple};
  padding: 24px;
  align-items: stretch;

  @media screen and ${device.tablet} {
    grid-template-rows: auto 1fr 2fr 3fr auto;
  }

  @media screen and ${device.desktop} {
    grid-template-rows: auto 1fr 3fr 3fr auto;
  }
`;

interface LabelProps {
  color: string;
}

const Label = styled.span<LabelProps>`
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => props.color};
`;

const Title = styled.h3`
  margin: 0;
`;

const Excerpt = styled.p`
  padding: 0;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

interface Props {
  labelTop: string;
  title: string;
  excerpt?: string;
  excerptRaw?: string;
  featured_image: string;
  link: string;
  color: string;
}

const FeaturedProgramme: React.FC<Props> = function (props) {
  const {
    labelTop,
    title,
    excerpt,
    excerptRaw,
    featured_image,
    link,
    color,
  } = props;

  return (
    <Wrapper {...props}>
      <Label color={color}>{labelTop}</Label>
      <Title>{title}</Title>
      <Excerpt>
        {excerpt && <MDX>{excerpt}</MDX>}
        {excerptRaw}
      </Excerpt>
      <Image src={featured_image} />
      <LinkButton
        buttonText="Find out more"
        buttonType="outline"
        background="#FFF"
        link={link}
      />
    </Wrapper>
  );
};

export default FeaturedProgramme;
