import React from 'react';
import styled from 'styled-components';

interface InputProps {
  marginBottom?: number;
}
const InputField = styled.input<InputProps>`
  font-size: 15px;
  padding: 0.5rem ${props => props.theme.paddings.halfpd}px;
  margin-bottom: ${props => props.marginBottom ?? 0}px;
  border: 1px solid ${props => props.theme.colors.purple};
  background-color: transparent;

  &::placeholder {
    color: ${props => props.theme.colors.purple};
  }
`;

interface Props {
  type: string;
  placeholder: string;
  marginBottom?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  required?: boolean;
}

const Input: React.FC<Props> = function (props) {
  const { type, placeholder, onChange, marginBottom, value, required } = props;

  return (
    <InputField
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      marginBottom={marginBottom}
      value={value}
      required={required}
    />
  );
};

export default Input;
