import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface ButtonProps {
  background: string;
  color: string;
  borderColor: string;
  noHoverEffects?: boolean;
}

const ButtonWrapper = styled.button<ButtonProps>`
  background: ${props => props.background ?? props.theme.colors.purple};
  color: ${props => props.color ?? 'white'};
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  padding: 0.5rem ${props => props.theme.paddings.halfpd}px;
  border: 1px solid ${props => props.borderColor ?? props.theme.colors.purple};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  cursor: pointer;

  transition: background ease-in-out 30ms, color ease-in-out 30ms;

  ${props => {
    props.noHoverEffects
      ? ''
      : `
        &:hover {
          color: ${props => props.background ?? props.theme.colors.purple};
          background: ${props => props.color ?? 'white'};
        }
    `;
  }}
`;

interface Props {
  buttonText: string;
  link: string;
  buttonType: keyof typeof theme['buttons'];
  background?: string; // override
  screenreaderText?: string;
  noLink?: boolean;
  noHoverEffects?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}

const LinkButton: React.FC<Props> = function (props) {
  const {
    buttonText,
    link,
    onClick,
    buttonType,
    background,
    screenreaderText,
    noHoverEffects,
    noLink,
    className,
  } = props;

  const colors = !theme.buttons[buttonType]
    ? theme.buttons.primary
    : theme.buttons[buttonType];

  return !noLink ? (
    <Link className={className} to={link} onClick={onClick}>
      {props.buttonType && (
        <ButtonWrapper
          background={background ?? colors.background}
          color={colors.color}
          borderColor={colors.borderColor}
          noHoverEffects={noHoverEffects}
          title={screenreaderText}
        >
          {buttonText}
        </ButtonWrapper>
      )}
    </Link>
  ) : (
    <>
      {props.buttonType && (
        <ButtonWrapper
          background={background ?? colors.background}
          color={colors.color}
          borderColor={colors.borderColor}
          noHoverEffects={noHoverEffects}
          title={screenreaderText}
          onClick={onClick}
        >
          {buttonText}
        </ButtonWrapper>
      )}
    </>
  );
};

export default LinkButton;
