import { keyBy } from 'lodash';
import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import SiteConfigContext from '../context/SiteConfigContext';
import UsersContext from '../context/UsersContext';
import ColouredPageSection from './ColouredPageSection';
import CommitteeMember from './CommitteeMember';
import FeaturedProgrammesContainer from './FeaturedProgrammesContainer';

interface QueryData {
  siteCfg: {
    id: string;
    childMdx: {
      frontmatter: {
        people_behind_sdea: {
          role: string;
          email: string;
        }[];
      };
    };
  };

  users: {
    edges: {
      node: {
        id: string;
        childMdx: {
          frontmatter: {
            name: string;
            email: string;
            bio: string;
            profile_image: string;
          };
        };
      };
    }[];
  };
}

interface Props {
  title: string;
  description: string;
}

const PeopleBehindSDEA: React.FC<Props> = function (props) {
  const { title, description } = props;
  const theme = useTheme();

  const siteCfg = useContext(SiteConfigContext);
  const users = useContext(UsersContext);

  const { people_behind_sdea } = siteCfg;
  const allUsers = keyBy(users, 'email');

  return (
    <ColouredPageSection
      firstColor={theme.colors.coral}
      title={title}
      sectionDescription={description}
    >
      <FeaturedProgrammesContainer>
        {people_behind_sdea
          .map(person => ({ ...person, ...allUsers[person.email] }))
          .map(person => (
            <CommitteeMember
              key={person.email}
              title={person.role}
              name={person.name}
              excerpt={person.bio}
              image={person.profile_image}
            />
          ))}
      </FeaturedProgrammesContainer>
    </ColouredPageSection>
  );
};

export default PeopleBehindSDEA;
