import Event from './components/Event';
import EventCalendar from './components/EventCalendar';
import FeatureSDEAProgrammes from './components/FeatureSDEAProgrammes';
import Section from './components/formatting/Section';
import Image from './components/formatting/Image';
import OurWishList from './components/OurWishlist';
import PageTopSection from './components/PageTopSection';
import Paragraph from './components/formatting/Paragraph';
import PeopleBehindSDEA from './components/PeopleBehindSDEA';
import PastCommittees from './components/PastCommittees';
import Resources from './components/resources/Resources';
import OnlineForm from './components/apply/OnlineForm';
import MembershipApplyContainer from './components/MembershipApplyContainer';
import DownloadForm from './components/apply/DownloadForm';
import SubscribeForm from './components/forms/SubscribeForm';
import Accordion from './components/Accordion';
import RegisterButton from './components/RegisterButton';
import TableDataCell from './components/formatting/TableDataCell';
import Table from './components/formatting/Table';
import Video from './components/formatting/Video';
import TableHeader from './components/formatting/TableHeader';
import HorizontalRule from './components/formatting/HorizontalRule';
// NOTE: These are all the components that can be rendered
// by MDX!

const mdxComponents = {
  Event,
  EventCalendar,
  FeatureSDEAProgrammes,
  Section,
  img: Image,
  p: Paragraph,
  PageTopSection,
  OurWishList,
  PeopleBehindSDEA,
  PastCommittees,
  Resources,
  SubscribeForm,
  DownloadForm,
  OnlineForm,
  MembershipApplyContainer,
  Accordion,
  RegisterButton,
  td: TableDataCell,
  th: TableHeader,
  table: Table,
  video: Video,
  hr: HorizontalRule,
};

export default mdxComponents;
