import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import firebase from 'gatsby-plugin-firebase';

const Form = styled.form``;

interface FormSectionProps {
  singleColumn?: boolean;
}

const FormSection = styled.fieldset<FormSectionProps>`
  display: grid;
  grid-template-columns: ${props =>
    props.singleColumn ? '1fr' : 'repeat(auto-fill, minmax(400px, 1fr))'};
  border: 3px ${props => props.theme.colors.purple} solid;
  column-gap: 48px;
  margin-bottom: 4px;
`;

const Legend = styled.legend`
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  color: ${props => props.theme.colors.purple};
`;

interface FieldContainerProps {
  row?: boolean;
}

const FieldContainer = styled.div<FieldContainerProps>`
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 0.9em;
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  row-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const LabelWithCheckBox = styled.label`
  font-size: 0.9em;
  margin-right: 16px;

  & > *:first-child {
    margin-right: 8px;
  }
  font-weight: 400;
`;

const Error = styled.span`
  color: #d84345;
`;

const Select = styled.select`
  font-size: 1em;
  font-weight: 400;
`;

const Input = styled.input`
  font-family: Open Sans, sans-serif;
  font-size: 1em;
  border: 2px solid ${props => props.theme.colors.purple};
  padding: 2px 8px;
  font-weight: 400;
  height: fit-content;
`;

const SignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

interface MembershipApplyData {
  type: 'ordinary' | 'associate';
  // personal
  salutation: string;
  name: string;
  gender: string;
  dateOfBirth: string;
  address: string;
  mobile: string;
  email: string;
  officePhone?: string;
  housePhone?: string;
  nationality: string;
  nationalityOther: string;
  // professional
  highestQualification: string;
  employmentStatus: string;
  employers?: string;
  cv: FileList;
  cvFileName: string;
  // interests
  areasOfInterest: string[];
  areasOfInterestOther: string;
  elaboration: string;
  volunteerAreas: string[];
  volunteerAreasOther: string;
  referralSource: string;
  selfBenefit: string;
  // declaration
  confirmation: boolean;
  code: boolean;
  data: boolean;
  // contactPoints
  emailMe: boolean;
  phoneMe: boolean;
  whatsAppMe: boolean;
  telegramMe: boolean;
  smsMe: boolean;
  mailMe: boolean;
  ceaseNotificationUponQuit: boolean;
  truthful: boolean;
}

// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const OnlineForm: React.FC = function () {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm<
    MembershipApplyData
  >();

  const handleFormSubmit = useCallback((data: MembershipApplyData) => {
    async function submit() {
      // Convert the CV file
      const cvFile = data.cv.item(0);
      const cv = ((await toBase64(cvFile)) as string).replace(
        /^data:.+;base64,/,
        ''
      );
      await firebase.functions().httpsCallable('membershipApply')({
        ...data,
        cv,
        cvFilename: cvFile?.name,
      });

      setIsSubmitting(false);
      setIsSubmitted(true);
    }

    setIsSubmitting(true);
    submit();
  }, []);

  const {
    employmentStatus,
    contactMe,
    nationality,
    name,
    type,
    areasOfInterest,
    volunteerAreas,
  } = watch();

  return (
    <Form action="#" onSubmit={handleSubmit(handleFormSubmit)}>
      <p>
        Please fill in the form below to apply for Ordinary or Associate
        Membership. Membership is subject to approval, and is reviewed annually
        by the Elected Committee. We will be in touch with you after reviewing
        your application.
      </p>
      <FormSection>
        <Legend>Membership Type</Legend>
        <Label>
          I am applying for&nbsp;
          <Select name="type" required ref={register({ required: true })}>
            <option selected disabled hidden>
              Select a membership type
            </option>
            <option>Ordinary Membership</option>
            <option>Associate Membership</option>
          </Select>
          {errors.type && <Error>This field is required.</Error>}
        </Label>
      </FormSection>
      <FormSection>
        <Legend>Your Personal Details</Legend>

        <FieldContainer>
          <Label>
            Your Full Name*
            <Input
              name="name"
              type="text"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.name && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Gender*
            <Input
              name="gender"
              type="text"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.gender && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Date of Birth*
            <Input
              name="dateOfBirth"
              type="date"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.dateOfBirth && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Address*
            <Input
              name="address"
              type="text"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.address && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Mobile*
            <Input
              name="mobile"
              type="tel"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.mobile && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Email*
            <Input
              name="email"
              type="email"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.email && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Office Phone
            <Input
              name="office-phone"
              type="tel"
              placeholder=""
              ref={register}
            />
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            House Phone
            <Input
              name="house-phone"
              type="tel"
              placeholder=""
              ref={register}
            />
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Nationality
            <Select
              name="nationality"
              required
              ref={register({ required: true })}
            >
              <option>Singapore Citizen</option>
              <option>Singapore Permanent Resident</option>
              <option>Other</option>
            </Select>
            {errors.nationality && <Error>This field is required.</Error>}{' '}
          </Label>
        </FieldContainer>

        {nationality === 'Other' && (
          <FieldContainer>
            <Label>
              If other, please specify:
              <Input
                name="nationalityOther"
                type="text"
                placeholder=""
                required
                ref={register}
              />
              {errors.nationality && <Error>This field is required.</Error>}
            </Label>
          </FieldContainer>
        )}
      </FormSection>
      <FormSection>
        <Legend>Professional Experience</Legend>

        <FieldContainer>
          <Label>
            Please state your highest education qualification attained, as well
            as the awarding institution:
            <Input
              name="highestQualification"
              type="text"
              placeholder=""
              required
              ref={register({ required: true })}
            />
            {errors.highestQualification && (
              <Error>This field is required.</Error>
            )}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            Current employment status:
            <Select
              name="employmentStatus"
              required
              ref={register({ required: true })}
            >
              <option selected disabled hidden>
                Select one
              </option>
              <option>Working full-time</option>
              <option>Freelancing</option>
              <option>Working permanent part-time</option>
              <option>Working part-time</option>
              <option>Not currently working</option>
            </Select>
            {errors.employmentStatus && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        {employmentStatus !== 'Not currently working' ? (
          <FieldContainer>
            <Label>
              If employed, please provide us with the name(s) of your
              employer(s):
              <Input
                name="employers"
                type="text"
                placeholder=""
                ref={register}
              />
              {errors.employers && <Error>This field is required.</Error>}
            </Label>
          </FieldContainer>
        ) : (
          <div />
        )}

        <FieldContainer>
          <Label>
            Please attach your curriculum vitae:
            <Input
              name="cv"
              type="file"
              placeholder=""
              ref={register({ required: true })}
            />
          </Label>
          <Label htmlFor="cv">
            File types accepted: .txt, .pdf, .doc, .docx, .rtf. File size must
            be 1mb or less.
            {errors.cv && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>
      </FormSection>
      <FormSection singleColumn>
        <Legend>Personal Interests</Legend>
        <Label>Area(s) of interest in the Arts:</Label>

        <FieldContainer row>
          {[
            'Applied Arts',
            'Arts Education (Applied)',
            'Arts Education (Theatre)',
            'Arts Making',
            'Arts Management',
            'Other',
          ].map(option => (
            <LabelWithCheckBox key={option}>
              <Input
                type="checkbox"
                name="areasOfInterest"
                id={option}
                value={option}
                ref={register}
              />
              {option}
            </LabelWithCheckBox>
          ))}
        </FieldContainer>
        {areasOfInterest?.indexOf('Other') !== -1 && (
          <FieldContainer>
            <Label>
              If other, please specify:
              <Input type="text" name="areasOfInterestOther" ref={register} />
            </Label>
          </FieldContainer>
        )}

        <FieldContainer>
          <Label>
            For each area, please elaborate:
            <Input type="text" name="elaboration" ref={register} />
          </Label>
        </FieldContainer>

        <Label>Area(s) I would like to volunteer in as a member:</Label>

        <FieldContainer row>
          {[
            'Facilitation',
            'Programming',
            'Research',
            'Copywriting',
            'Graphic Design',
            'Photography/Videography',
            'Front of House Duties',
            'Note Taking',
            'Other',
          ].map(option => (
            <LabelWithCheckBox key={option}>
              <Input
                type="checkbox"
                name="volunteerAreas"
                id={option}
                value={option}
                ref={register}
              />
              {option}
            </LabelWithCheckBox>
          ))}
        </FieldContainer>
        {volunteerAreas?.indexOf('Other') !== -1 && (
          <FieldContainer>
            <Label>
              If other, please specify:
              <Input type="text" name="volunteerAreasOther" ref={register} />
            </Label>
          </FieldContainer>
        )}

        <FieldContainer>
          <Label>
            How did you hear about SDEA?
            <Select
              name="referralSource"
              required
              ref={register({ required: true })}
            >
              <option>Facebook</option>
              <option>Instagram</option>
              <option>Website</option>
              <option>Word-of-mouth</option>
              <option>Events</option>
              <option>Roadshows</option>
            </Select>
            {errors.referralSource && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>

        <FieldContainer>
          <Label>
            How would you like to benefit from being an SDEA Member?
            <Input
              type="text"
              name="selfBenefit"
              required
              ref={register({ required: true })}
            />
            {errors.selfBenefit && <Error>This field is required.</Error>}
          </Label>
        </FieldContainer>
      </FormSection>
      <FormSection singleColumn>
        <Legend>Declaration</Legend>

        <FieldContainer row>
          <LabelWithCheckBox>
            <Input
              type="checkbox"
              name="confirmation"
              required
              ref={register({ required: true })}
            />
            I wish to apply for{' '}
            {type === 'Select a membership type' ? '...' : type} in the
            Singapore Drama Educators Association (SDEA) and undertake to
            conform to the{' '}
            <Link to="/about/who-we-are/sdea-constitution">
              Constitution of SDEA
            </Link>
          </LabelWithCheckBox>
          {errors.selfBenefit && <Error>This field is required.</Error>}
        </FieldContainer>

        <FieldContainer row>
          <LabelWithCheckBox>
            <Input
              type="checkbox"
              name="code"
              required
              ref={register({ required: true })}
            />
            As a member of SDEA, I agree to abide by the{' '}
            <Link to="/about/who-we-are/sdea-code-of-professional-practice">
              Code of Professional Practice
            </Link>
            {errors.code && <Error>This field is required.</Error>}
          </LabelWithCheckBox>
        </FieldContainer>

        <FieldContainer row>
          <LabelWithCheckBox>
            <Input
              type="checkbox"
              name="data"
              required
              ref={register({ required: true })}
            />
            I have read and understood&nbsp;
            <Link to="/about/who-we-are/sdea-data-protection-notice">
              SDEA's Data Protection Notice
            </Link>
            &nbsp;on the collection, use, and disclosure of my personal data
            under the Personal Data Protection Act
            {errors.data && <Error>This field is required.</Error>}
          </LabelWithCheckBox>
        </FieldContainer>

        <FieldContainer>
          <Label>
            I wish to be contacted and notified of future events/happenings from
            sdea via my preferred mode(s) of contact:
          </Label>
          <FieldContainer row>
            <LabelWithCheckBox>
              <Input type="checkbox" name="emailMe" ref={register} />
              Email
            </LabelWithCheckBox>
            <LabelWithCheckBox>
              <Input type="checkbox" name="phoneMe" ref={register} />
              Phone
            </LabelWithCheckBox>
            <LabelWithCheckBox>
              <Input type="checkbox" name="whatsAppMe" ref={register} />
              WhatsApp
            </LabelWithCheckBox>
            <LabelWithCheckBox>
              <Input type="checkbox" name="telegramMe" ref={register} />
              Telegram
            </LabelWithCheckBox>
            <LabelWithCheckBox>
              <Input type="checkbox" name="smsMe" ref={register} />
              SMS
            </LabelWithCheckBox>
            <LabelWithCheckBox>
              <Input type="checkbox" name="mailMe" ref={register} />
              Snail Mail
            </LabelWithCheckBox>
          </FieldContainer>
        </FieldContainer>

        <FieldContainer row>
          <LabelWithCheckBox>
            <Input
              type="checkbox"
              name="ceaseNotificationUponQuit"
              ref={register}
            />
            I do not wish to be contacted or notified of any events/happenings
            from sdea should i cease being a member of sdea, and will notify
            sdea in writing accordingly.
            {errors.ceaseNotificationUponQuit && (
              <Error>This field is required.</Error>
            )}
          </LabelWithCheckBox>
        </FieldContainer>

        <FieldContainer row>
          <LabelWithCheckBox>
            <Input
              type="checkbox"
              name="truthful"
              required
              ref={register({ required: true })}
            />
            I certify that all the information provided in this application is
            true and complete.
          </LabelWithCheckBox>
          {errors.truthful && <Error>This field is required.</Error>}
        </FieldContainer>
      </FormSection>
      <SignContainer>
        <span>Name: {name}</span>
        <span>Date: {moment().format('Do MMMM yyyy')}</span>
      </SignContainer>
      {isSubmitting && <span>Submitting...</span>}
      <Input
        type="submit"
        value={isSubmitted ? 'Submitted!' : 'Submit'}
        disabled={isSubmitted}
      />
    </Form>
  );
};

export default OnlineForm;
