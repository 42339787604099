import styled from 'styled-components';
import { device } from '../constants';

const FeaturedProgrammesContainer = styled.div`
  display: grid;
  grid-auto-flow: rows;

  grid-template-columns: 1fr;
  column-gap: 24px;
  row-gap: 16px;

  @media screen and ${device.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
`;

export default FeaturedProgrammesContainer;
