import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow-x: scroll;
  padding-left: 1px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
`;

const Table: React.FC = function (props) {
  const { children } = props;

  return (
    <Wrapper>
      <StyledTable>{children}</StyledTable>
    </Wrapper>
  );
};

export default Table;
