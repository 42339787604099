import styled from 'styled-components';

const TableHeader = styled.th`
  border: 1px solid #999;
  background-color: #f6f6f6;
  padding: 0.5rem;
  font-weight: 500;
  text-align: left;
`;

export default TableHeader;
