import React from 'react';
import styled from 'styled-components';

interface AnchorProps {
  background?: string;
  color?: string;
}

const Anchor = styled.a<AnchorProps>`
  color: ${props => props.color ?? props.theme.colors.purple};
  font-size: 15px;
  padding: 0.5rem ${props => props.theme.paddings.halfpd}px;
  border: 1px solid ${props => props.background ?? props.theme.colors.purple};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: ${props => props.color ?? props.theme.colors.purple};
    color: white;
  }
`;

interface Props extends AnchorProps {
  href: string;
}

const LinkAnchor: React.FC<Props> = function (props) {
  const { children } = props;

  return <Anchor {...props}>{children}</Anchor>;
};

export default LinkAnchor;
