import React, { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import SiteConfigContext from '../context/SiteConfigContext';
import ColouredPageSection from './ColouredPageSection';
import Wish from './donate/Wish';

const Wishlist = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  column-gap: 16px;
`;

interface Props {
  title: string;
  description: string;
}

const OurWishList: React.FC<Props> = function (props) {
  const { title, description } = props;

  const theme = useTheme();
  const siteCfg = useContext(SiteConfigContext);

  const { donate_wishlist } = siteCfg;

  return (
    <ColouredPageSection
      firstColor={theme.colors.blue}
      title={title}
      sectionDescription={description}
    >
      <Wishlist>
        {donate_wishlist.map(wish => (
          <Wish key={wish.title} wish={wish} />
        ))}
      </Wishlist>
    </ColouredPageSection>
  );
};

export default OurWishList;
