import { Link } from '@reach/router';
import { groupBy, orderBy, toPairs } from 'lodash';
import React, { useContext, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import CommitteesContext from '../context/CommitteesContext';
import generateSlug from '../util/generateSlug';
import ColouredPageSection from './ColouredPageSection';
import FeaturedProgrammesContainer from './FeaturedProgrammesContainer';
import SectionTitle from './formatting/SectionTitle';

const StyledLink = styled(Link)`
  display: flex;
`;

const Committee = styled.div`
  width: 100%;
  display: grid;
  overflow: hidden;

  grid-template-rows: 1fr 2fr;
  row-gap: 16px;
  background-color: white;
  border: 1px solid ${props => props.theme.colors.purple};
  padding: 24px;
`;

const CommitteeLabel = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => props.theme.colors.purple};
`;

const CommitteeTitle = styled.h3`
  margin: 0;
`;

interface Props {
  title: string;
  description: string;
}

const PastCommittees: React.FC<Props> = function (props) {
  const { title, description } = props;

  const theme = useTheme();
  const rawCommittees = useContext(CommitteesContext);

  const committees = useMemo(() => {
    return orderBy(
      rawCommittees,
      [
        committee =>
          committee.end_year ?? committee.title.match(/(\d{4})/)?.[1] ?? null,
        committee =>
          committee.start_year ??
          committee.end_year ??
          committee.title.match(/(\d{4})/)?.[1] ??
          null,
      ],
      ['desc', 'desc']
    );
  }, [rawCommittees]);

  const entries = useMemo(() => {
    const groupedCommittees = groupBy(
      committees ?? [],
      committee => committee.tags ?? ['Committee'] // Fallback
    );

    const entries = toPairs(groupedCommittees);

    return orderBy(
      entries,
      pair => pair[0].toLowerCase().includes('executive'),
      ['desc']
    );
  }, [committees]);

  return (
    <ColouredPageSection
      firstColor={theme.colors.lightGray}
      title={title}
      sectionDescription={description}
    >
      {entries.map(([tags, sectionCommittees]) => (
        <div key={tags}>
          <SectionTitle>{tags?.split(',')?.join(', ') || ''}</SectionTitle>
          <FeaturedProgrammesContainer>
            {sectionCommittees.map(({ id, title, start_year, end_year }) => (
              <StyledLink key={id} to={`/committee/${generateSlug(title)}`}>
                <Committee>
                  <CommitteeLabel>
                    {start_year
                      ? `${start_year}-${end_year}`
                      : title.match(/(\d{4})/)?.[1] || new Date().getFullYear()}
                  </CommitteeLabel>
                  <CommitteeTitle>{title}</CommitteeTitle>
                </Committee>
              </StyledLink>
            ))}
          </FeaturedProgrammesContainer>
        </div>
      ))}
    </ColouredPageSection>
  );
};

export default PastCommittees;
