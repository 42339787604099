import { truncate } from 'lodash';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;

  background-color: white;
  border: 1px solid ${props => props.theme.colors.purple};
  padding: 24px;
`;

interface TitleProps {
  color: string;
}

const Title = styled.span<TitleProps>`
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => props.color};
`;

const Name = styled.h3`
  margin: 0;
  flex-grow: 0;
`;

const Excerpt = styled.p`
  padding: 0;
  flex-grow: 1;
`;

const Image = styled.img`
  width: 100%;
`;

const ReadMoreButton = styled.button`
  background: transparent;
  border: 1px solid ${props => props.theme.colors.purple};
`;

interface Props {
  title: string;
  name?: string;
  excerpt?: string;
  image: string;
}

const CommitteeMember: React.FC<Props> = function (props) {
  const { title, excerpt, name, image } = props;

  const theme = useTheme();

  const truncatedExcerpt = truncate(excerpt, { length: 100 });

  const [isFullExcerptDisplayed, setIsFullExcerptDisplayed] = useState(false);

  return (
    <Wrapper {...props}>
      <Title color={theme.colors.purple}>{title}</Title>
      <Name>{name}</Name>
      <Excerpt>
        {isFullExcerptDisplayed ? excerpt : truncatedExcerpt}
        {truncatedExcerpt.length > 0 &&
          truncatedExcerpt.length !== excerpt?.length && (
            <ReadMoreButton
              onClick={() => setIsFullExcerptDisplayed(!isFullExcerptDisplayed)}
            >
              Read {isFullExcerptDisplayed ? 'Less' : 'More'}
            </ReadMoreButton>
          )}
      </Excerpt>
      <Image src={image} />
    </Wrapper>
  );
};

export default CommitteeMember;
