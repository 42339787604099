import React from 'react';
import { ThemeProvider } from 'styled-components';
import './src/site.css';
import theme from './src/theme';
import { MDXProvider } from '@mdx-js/react';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import mdxComponents from './src/mdxComponents';
import CMSDataProvider from './src/cms/CMSDataProvider';

export const wrapRootElement = function ({ element }) {
  return (
    <ThemeProvider theme={theme}>
      <MDXProvider components={mdxComponents}>
        <CMSDataProvider>{element}</CMSDataProvider>
      </MDXProvider>
    </ThemeProvider>
  );
};
