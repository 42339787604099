import React, { useContext } from 'react';
import EventContext from '../context/EventContext';
import LinkAnchor from './form-elements/LinkAnchor';

const RegisterButton: React.FC = function (props) {
  const event = useContext(EventContext);

  return <LinkAnchor href={event?.rsvp_link}>Register</LinkAnchor>;
};

export default RegisterButton;
