import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from './form-elements/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildrenWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.lightGray};
  padding: 0px 24px;
`;

interface Props {
  header: string;
}

const Accordion: React.FC<Props> = function (props) {
  const { children, header } = props;
  const [visible, setVisible] = useState(false);

  const theme = useTheme();

  return (
    <Wrapper>
      <Button
        buttonType={visible ? 'outline' : 'primary'}
        background={visible ? 'white' : theme.colors.purple}
        onClick={() => setVisible(v => !v)}
      >
        {header}
      </Button>
      <ChildrenWrapper>{visible && children}</ChildrenWrapper>
    </Wrapper>
  );
};

export default Accordion;
