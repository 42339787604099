import styled from 'styled-components';
import { device } from '../../constants';

const Section = styled.div`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: ${props => props.theme.paddings.pd}px
      ${props => props.theme.paddings.pd * 2.5}px;
  }
`;

export default Section;
