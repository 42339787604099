const theme = {
  colors: {
    main: '#000',
    secondary: '#555',
    purple: '#80225F',
    blue: '#5E98BA',
    coral: '#FF8661',
    lightGray: '#E8EBED',
    orangeGray: '#241F19',
  },
  paddings: {
    pd: 40,
    halfpd: 20,
  },
  buttons: {
    primary: {
      background: '#80225F',
      color: '#FFF',
      borderColor: '#80225F',
    },
    outline: {
      background: 'transparent',
      color: '#80225F',
      borderColor: '#80225F',
    },
    outlineWhite: {
      background: 'transparent',
      color: '#FFF',
      borderColor: '#FFF',
    },
    active: {
      background: '#E8EBED',
      color: '#241F19',
      borderColor: '#E8EBED',
    },
  },
};

export default theme;
