import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import FullCalendar, {
  EventApi,
  EventContentArg,
  EventInput,
  MountArg,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import styled, { useTheme } from 'styled-components';
import CategoryLabel, { Category } from './CategoryLabel';
import { flatten, intersection, keyBy, sortBy, uniq } from 'lodash';
import 'typeface-open-sans';
import { usePopper } from 'react-popper';
import ColouredPageSection from './ColouredPageSection';
import moment from 'moment';
import generateSlug from '../util/generateSlug';
import { device } from '../constants';
import EventsContext from '../context/EventsContext';
import SiteConfigContext from '../context/SiteConfigContext';
import EventTagsContext from '../context/EventTagsContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${device.laptop} {
    flex-direction: row;
  }

  & > .fc {
    flex-grow: 1;
  }

  .fc-toolbar-title {
    color: ${props => props.theme.colors.purple};
  }

  .fc-today-button,
  .fc-prev-button,
  .fc-next-button {
    background-color: ${props => props.theme.colors.purple};
  }

  .fc-event-time,
  .fc-event-title,
  .fc-col-header-cell-cushion,
  .fc-daygrid-day-number {
    font-family: 'Open Sans', sans-serif;
  }

  .fc-event-time {
    font-weight: 400;
    flex-shrink: 0;
  }

  .fc-event-title {
    font-weight: 700;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;

  & > * {
    margin-bottom: 16px;
  }
`;

const Tooltip = styled.div`
  width: 250px;
  overflow: hidden;
  background-color: #f2d274;
  border-radius: 5px;
  padding: 11px;
  font-family: 'Open Sans', sans-serif;

  display: flex;
  flex-direction: column;
`;

const TooltipHeader = styled.div`
  margin-bottom: 12px;
`;

const TooltipTime = styled.span`
  font-family: 'Open Sans', sans-serif;
  margin-right: 8px;
`;

const TooltipTitle = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
`;

const TooltipContent = styled.span`
  font-family: 'Open Sans', sans-serif;
`;

const TooltipArrow = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;

  &:before {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1;
  }

  &:before {
    top: -10px;
    content: '';
    transform: rotate(45deg);
    background-color: #f2d274;
  }
`;

const EventCalendar: React.FC = function () {
  const rawEvents = useContext(EventsContext) ?? [];

  const allTags = useContext(EventTagsContext);
  const allTagsKeyed = keyBy(allTags, 'name');

  const siteCfg = useContext(SiteConfigContext);
  const sidebarFilters =
    siteCfg?.event_calendar_configuration?.sidebar_filters ?? [];

  const sidebarTags = sortBy(
    sidebarFilters.map(filter => allTagsKeyed[filter.tag]),
    'label'
  );

  const [selectedTags, setSelectedTags] = useState(
    sidebarTags.map(tag => tag.label)
  );

  const theme = useTheme();

  const events: EventInput[] = useMemo(() => {
    return flatten(
      rawEvents.map(event => {
        const { title, date_ranges, tags, content, excerpt } = event;

        return (
          date_ranges?.map(({ start_date, end_date }) => ({
            title,
            start: new Date(start_date),
            end: new Date(end_date),
            allDay: start_date === end_date,
            backgroundColor: theme.colors.purple,
            color: theme.colors.purple,
            textColor: 'white',
            display: 'block',
            extendedProps: {
              content,
              excerpt,
            },
            tags: tags,
            url: `/event/${generateSlug(title)}`,
          })) ?? []
        );
      })
    );
  }, [rawEvents, theme]);

  const visibleEvents = useMemo(() => {
    return events.filter(
      event =>
        event.tags == null ||
        event.tags.length === 0 ||
        intersection(selectedTags, event.tags).length > 0
    );
  }, [selectedTags, events]);

  const handleTagClicked = useCallback((cat: Category) => {
    setSelectedTags(currentTags => {
      if (currentTags.indexOf(cat.label) !== -1) {
        return currentTags.filter(cid => cid !== cat.label);
      }
      return [...currentTags, cat.label];
    });
  }, []);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 5 } },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
    placement: 'top',
  });
  const [hoverEvent, setHoverEvent] = useState<EventApi | null>(null);

  const handleEventRender = useCallback((info: MountArg<EventContentArg>) => {
    const { el } = info;
    el.addEventListener('mouseenter', () => {
      setReferenceElement(el);
      setHoverEvent(info.event);
    });
    el.addEventListener('mouseleave', () => {
      setReferenceElement(null);
      setHoverEvent(null);
    });
  }, []);

  return (
    <ColouredPageSection
      title="Event Calendar"
      firstColor="#fff"
      sectionDescription="View all upcoming events by month, filter by event category, hover over to view event details and click on the event to reach the relevant organiser’s website."
    >
      <Wrapper>
        <Categories>
          {sidebarTags.map((tag: App.EventTag) => (
            <CategoryLabel
              key={tag.label}
              category={{ ...tag, id: tag.label }}
              checked={selectedTags.indexOf(tag.label) !== -1}
              onClick={handleTagClicked}
            />
          ))}
        </Categories>
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 9999 }}
          {...attributes.popper}
        >
          {hoverEvent && (
            <>
              <Tooltip>
                <TooltipHeader>
                  {!hoverEvent.allDay && (
                    <TooltipTime>
                      {moment(hoverEvent.start).format('hha')}
                    </TooltipTime>
                  )}
                  <TooltipTitle>{hoverEvent.title}</TooltipTitle>
                </TooltipHeader>
                <TooltipContent>
                  {hoverEvent.extendedProps.excerpt}
                </TooltipContent>
              </Tooltip>
              <TooltipArrow ref={setArrowElement} style={styles.arrow} />
            </>
          )}
        </div>

        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={visibleEvents}
          eventDidMount={handleEventRender}
        />
      </Wrapper>
    </ColouredPageSection>
  );
};

export default EventCalendar;
