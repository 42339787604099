import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import LinkButton from './form-elements/LinkButton';

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > * {
    margin-right: 8px;
  }
`;

interface Props {
  options: string[];
}

const MembershipApplyContainer: React.FC<Props> = function (props) {
  const { children, options } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const theme = useTheme();

  const childrenArray = React.Children.toArray(children);

  return (
    <div>
      <ButtonContainer>
        {options.map((option, index) => (
          <LinkButton
            key={option}
            onClick={e => {
              e.preventDefault();
              setSelectedIndex(index);
            }}
            link="#"
            buttonText={option}
            buttonType={index === selectedIndex ? 'outline' : 'primary'}
            background={index === selectedIndex ? 'white' : theme.colors.purple}
          >
            {option}
          </LinkButton>
        ))}
      </ButtonContainer>
      {childrenArray[selectedIndex]}
    </div>
  );
};

export default MembershipApplyContainer;
