import React, { useContext } from 'react';
import styled from 'styled-components';
import SiteConfigContext from '../../context/SiteConfigContext';

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${props => props.theme.colors.lightGray};
`;

const StyledAnchor = styled.a`
  color: ${props => props.theme.colors.purple};
`;

const DownloadForm: React.FC = function () {
  const siteCfg = useContext(SiteConfigContext);

  return (
    <Wrapper>
      Click&nbsp;
      <StyledAnchor href={siteCfg?.membership_application_form_file}>
        here
      </StyledAnchor>
      &nbsp;to download the application form
    </Wrapper>
  );
};

export default DownloadForm;
