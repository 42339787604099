// Members' Engagement
import React, { useState, useCallback, useMemo, useContext } from 'react';
import { intersection } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { device } from '../../constants';
import Card from '../Card';
import CategoryLabel, { Category } from '../CategoryLabel';
import SectionDescription from '../formatting/SectionDescription';
import LinkAnchor from '../form-elements/LinkAnchor';
import ResourcesContext from '../../context/ResourcesContext';
import ResourceCategoriesContext from '../../context/ResourceCategoriesContext';

const ResourcesListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${props => props.theme.paddings.pd * 2}px;
`;

const LeftSidebarWrapper = styled.div`
  margin-right: ${props => props.theme.paddings.pd * 2}px;
  margin-bottom: ${props => props.theme.paddings.pd * 2}px;
  flex: 1 1 100%;

  @media screen and ${device.laptop} {
    flex: 0 1 400px;
  }
`;

const RightContentWrapper = styled.section`
  margin-right: auto;
  flex: 1 1 100%;

  @media screen and ${device.laptop} {
    flex: 1 0 650px;
  }
`;

const ResourcesContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  & > div {
    flex: 1 1 100%;
    margin-right: ${props => props.theme.paddings.pd}px;
    margin-bottom: ${props => props.theme.paddings.pd}px;
  }

  @media screen and ${device.tablet} {
    & > div {
      flex: 1 1 30%;
    }
  }
`;
const ExcerptWrapper = styled.div`
  margin-bottom: ${props => props.theme.paddings.pd}px;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.paddings.halfpd}px;
`;

const ResourcesListComponent: React.FC = function () {
  const resources = useContext(ResourcesContext);
  const categories = useContext(ResourceCategoriesContext);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const visibleResources = useMemo(() => {
    return resources.filter(
      (entry: any) =>
        entry.category === null ||
        entry.category.length === 0 ||
        intersection(selectedCategories, entry.category).length > 0
    );
  }, [selectedCategories, resources]);

  const handleCategoryClicked = useCallback((cat: Category) => {
    setSelectedCategories(categories => {
      if (categories.indexOf(cat.label) !== -1) {
        return categories.filter(c => c !== cat.label);
      }
      return [...categories, cat.label];
    });
  }, []);

  return (
    <ResourcesListContainer>
      <LeftSidebarWrapper>
        <SectionDescription>I'm looking for:</SectionDescription>
        {categories.length > 0
          ? categories.map((category: Category) => (
              <CategoryLabel
                key={category.id}
                category={category}
                checked={selectedCategories.indexOf(category.label) !== -1}
                onClick={handleCategoryClicked}
              />
            ))
          : 'No resource categories set up yet.'}
      </LeftSidebarWrapper>
      <RightContentWrapper>
        <ResourcesContainer>
          {visibleResources.length > 0 ? (
            visibleResources.map((entry: any, index: number) => {
              return (
                <Card title={entry.title} key={index}>
                  <ExcerptWrapper>{entry.excerpt}</ExcerptWrapper>

                  <LinkButtonWrapper>
                    <LinkAnchor href={entry.link ?? '#'} background="#aaaaaa">
                      External Link
                    </LinkAnchor>
                  </LinkButtonWrapper>
                </Card>
              );
            })
          ) : (
            <Card title="" subtitle="">
              No resources of{' '}
              {selectedCategories.length != 1 ? 'these types' : 'this type'}{' '}
              yet.
            </Card>
          )}
        </ResourcesContainer>
      </RightContentWrapper>
    </ResourcesListContainer>
  );
};

export default ResourcesListComponent;
