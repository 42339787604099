import { includes } from 'lodash';
import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import EventCategoriesContext from '../context/EventCategoriesContext';
import SiteConfigContext from '../context/SiteConfigContext';
import generateSlug from '../util/generateSlug';
import ColouredPageSection from './ColouredPageSection';
import FeaturedProgramme from './FeaturedProgramme';
import FeaturedProgrammesContainer from './FeaturedProgrammesContainer';

interface Props {
  title: string;
  description: string;
}

const FeatureSDEAProgrammes: React.FC<Props> = function (props) {
  const { title, description } = props;

  const siteCfg = useContext(SiteConfigContext);
  const event_categories = useContext(EventCategoriesContext);

  const { featured_programmes } = siteCfg;

  const theme = useTheme();

  return (
    <ColouredPageSection
      title={title}
      firstColor={theme.colors.coral}
      sectionDescription={description}
    >
      <FeaturedProgrammesContainer>
        {event_categories
          ?.filter(category => includes(featured_programmes, category.name))
          ?.map(category => (
            <FeaturedProgramme
              key={category.id}
              color={theme.colors.purple}
              excerptRaw={category.excerpt}
              labelTop={category.type}
              title={category.label}
              featured_image={category.hero_image}
              link={`/event_category/${generateSlug(category.label)}`}
            />
          ))}
      </FeaturedProgrammesContainer>
    </ColouredPageSection>
  );
};

export default FeatureSDEAProgrammes;
