import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CMSContextWrapper from './CMSContextWrapper';

export interface Data {
  committees: App.GraphQLConnection<App.MDXType<App.Committee>>;
  users: App.GraphQLConnection<App.MDXType<App.User>>;
  siteCfg: App.GraphQLConnection<App.MDXType<App.SiteConfig>>;
  event_categories: App.GraphQLConnection<App.MDXType<App.EventCategory>>;
  events: App.GraphQLConnection<App.MDXType<App.Event>>;
  event_tags: App.GraphQLConnection<App.MDXType<App.EventTag>>;
  event_venues: App.GraphQLConnection<App.MDXType<App.EventVenue>>;
  resources: App.GraphQLConnection<App.MDXType<App.Resource>>;
  resource_categories: App.GraphQLConnection<App.MDXType<App.ResourceCategory>>;
  journals: App.GraphQLConnection<App.MDXType<App.Journal>>;
}

export function useCMSData() {
  const data = useStaticQuery<Data>(graphql`
    query {
      siteCfg: allFile(
        filter: { sourceInstanceName: { eq: "site_configuration" } }
      ) {
        edges {
          node {
            id
            childMdx {
              frontmatter {
                menu_items {
                  name
                  link
                  children {
                    name
                    link
                    children {
                      name
                      link
                    }
                  }
                }
                featured_programmes
                people_behind_sdea {
                  role
                  email
                }
                donate_wishlist {
                  title
                  excerpt
                  thumbnail
                  link
                }
                membership_application_form_file
                homepage_events_section_description
                homepage_featured_events {
                  lhs_top
                  lhs_bottom
                  rhs
                }
                homepage_subscription_form {
                  title
                  short_description
                  long_description
                  mailto
                }
                journal_page_configuration {
                  title
                  description
                }
                journal_sidebar_links {
                  text
                  link
                }
                event_calendar_configuration {
                  sidebar_filters {
                    tag
                  }
                }
                dashboard_items {
                  name
                  link
                  children {
                    name
                    link
                  }
                }
                dashboard_upcoming_member_events_section {
                  title
                  description
                  background_color
                }
                social_share_networks
                footer_configuration {
                  links {
                    label
                    link
                  }
                  address
                  contact
                  social_links {
                    name
                    link
                    icon
                  }
                }
                events_past_projects_configuration {
                  block_description
                  page_description
                  past_events_description
                }
              }
            }
          }
        }
      }

      committees: allFile(filter: { sourceInstanceName: { eq: "committee" } }) {
        edges {
          node {
            id
            childMdx {
              frontmatter {
                title
                start_year
                end_year
                tags
              }
            }
          }
        }
      }

      users: allFile(filter: { sourceInstanceName: { eq: "user" } }) {
        edges {
          node {
            id
            childMdx {
              frontmatter {
                name
                email
                bio
                profile_image
              }
            }
          }
        }
      }

      event_categories: allFile(
        filter: { sourceInstanceName: { eq: "event_category" } }
      ) {
        edges {
          node {
            id
            name
            childMdx {
              id
              frontmatter {
                label
                excerpt
                hero_image
                type
                color
              }
            }
          }
        }
      }

      events: allFile(filter: { sourceInstanceName: { eq: "event" } }) {
        edges {
          node {
            id
            name
            childMdx {
              frontmatter {
                title
                date_ranges {
                  start_date
                  end_date
                }
                categories
                content
                excerpt
                tags
                address
              }
            }
          }
        }
      }

      event_tags: allFile(filter: { sourceInstanceName: { eq: "event_tag" } }) {
        edges {
          node {
            id
            name
            childMdx {
              frontmatter {
                label
              }
            }
          }
        }
      }

      event_venues: allFile(
        filter: { sourceInstanceName: { eq: "event_venue" } }
      ) {
        edges {
          node {
            id
            name
            childMdx {
              frontmatter {
                title
                address
                website
                postal_code
                country
                province
                phone
                show_map
              }
            }
          }
        }
      }

      resources: allFile(filter: { sourceInstanceName: { eq: "resources" } }) {
        edges {
          node {
            childMdx {
              frontmatter {
                title
                link
                excerpt
                category
              }
            }
          }
        }
      }

      resource_categories: allFile(
        filter: { sourceInstanceName: { eq: "resources_category" } }
      ) {
        edges {
          node {
            childMdx {
              frontmatter {
                label
              }
            }
          }
        }
      }

      journals: allFile(
        filter: { sourceInstanceName: { eq: "journal" } }
        sort: { fields: childMdx___frontmatter___pub_date, order: DESC }
      ) {
        edges {
          node {
            childMdx {
              frontmatter {
                title
                pub_date
                link
                excerpt
                dc_creator
              }
            }
          }
        }
      }
    }
  `);

  return data;
}

const CMSDataProvider: React.FC = function (props) {
  const { children } = props;
  const data = useCMSData();

  return <CMSContextWrapper {...data}>{children}</CMSContextWrapper>;
};

export default CMSDataProvider;
