import React from 'react';
import styled from 'styled-components';
import Section from './formatting/Section';
import breakpoint from 'styled-components-breakpoint';

const StyledSection = styled(Section)`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 32px;

  ${breakpoint('desktop')`
    grid-template-columns: 1fr 1fr;
  `}

  img {
    width: 100%;
  }
`;

const PageTopSection: React.FC = function (props) {
  const { children } = props;

  return <StyledSection>{children}</StyledSection>;
};

export default PageTopSection;
