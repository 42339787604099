import React from 'react';
import SectionDescription from '../formatting/SectionDescription';
import ResourcesListComponent from './ResourcesList';
import ColouredPageSection from '../ColouredPageSection';

const Resources: React.FC = function (props) {
  const listDescription =
    'Click on each category to find resources based on what you are looking for. This page will be updated on a daily basis.';
  const listTitle = 'Aggregated Resources';

  return (
    <ColouredPageSection
      colorStopValue="50"
      firstColor={'#E8EBED'} // @TODO: make configurable from CMS
      secondColor={'#fff'}
      title={listTitle}
      sectionDescription={listDescription}
    >
      <ResourcesListComponent />
      <SectionDescription>
        Did we miss something out? Let us know{' '}
        <a href="mailto:hello@sdea.org.sg">hello@sdea.org.sg</a>!
      </SectionDescription>
    </ColouredPageSection>
  );
};

export default Resources;
