import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  line-height: 50px;
`;

const SectionDescription: React.FC = function (props) {
  return <Wrapper>{props.children}</Wrapper>;
};

export default SectionDescription;
