import React from 'react';
import styled from 'styled-components';

export interface Category {
  id: string;
  label: string;
  color: string | null;
}

interface WrapperProps {
  color: string;
}

const Wrapper = styled.label<WrapperProps>`
  display: flex;
  align-items: center;
  color: ${props => props.color};
`;

const Label = styled.span`
  font-weight: 700;
`;

interface CheckBoxProps {
  color: string;
}

const CheckBox = styled.input<CheckBoxProps>`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  appearance: none;
  border: 3px solid ${props => props.color};
  background-clip: content-box;
  padding: 4px;
  margin-right: 8px;

  &:checked {
    background-color: ${props => props.color};
  }
`;

interface Props {
  category: Category;
  checked: boolean;
  onClick: (cat: Category) => void;
}

const CategoryLabel: React.FC<Props> = function (props) {
  const { category, checked, onClick } = props;

  const color = category.color ?? '#000000';

  return (
    <Wrapper color={color}>
      <CheckBox
        color={color}
        type="checkbox"
        checked={checked}
        onClick={() => onClick(category)}
      />
      <Label>{category.label}</Label>
    </Wrapper>
  );
};

export default CategoryLabel;
