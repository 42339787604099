import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import EventContext from '../context/EventContext';
import RegisterButton from './RegisterButton';
import MDX from '../cms/MDX';
import Section from './formatting/Section';
import SectionDescription from './formatting/SectionDescription';
import mdxComponents from '../mdxComponents';
import EventVenuesContext from '../context/EventVenuesContext';

const DateRangeFormattedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DateRangeWrapper = styled.time`
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  padding: 4px 8px;
  border: 2px solid ${props => props.theme.colors.purple};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.purple};
  color: #fff;
  margin: 2px 3px;

  @media screen and (min-width: 992px) {
    margin: 0 3px;
  }
`;

const DayOfWeek = styled.time`
  color: ${props => props.theme.colors.purple};
  background-color: #fff;
  padding: 0px 8px;
  border-radius: 3px;
  margin-right: 4px;
`;

const Hyphen = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.purple};
`;

interface DateRangeFormattedProps {
  start_date: string;
  end_date: string;
}

const DateRangeFormatted: React.FC<DateRangeFormattedProps> = function (props) {
  const { start_date, end_date } = props;

  const startDate = useMemo(() => moment(start_date), [start_date]);
  const endDate = useMemo(() => moment(end_date), [end_date]);

  const isSameDate = startDate.isSame(endDate, 'date');

  return (
    <DateRangeFormattedWrapper>
      <DateRangeWrapper>
        <DayOfWeek dateTime={start_date}>{startDate.format('ddd')}</DayOfWeek>
        <time dateTime={start_date}>{startDate.format('LL')}</time>
      </DateRangeWrapper>
      <DateRangeWrapper>
        <time dateTime={start_date}>{startDate.format('LT')}</time>
      </DateRangeWrapper>
      <Hyphen>&nbsp;-&nbsp;</Hyphen>
      {isSameDate ? (
        <DateRangeWrapper>
          <time dateTime={end_date}>
            {endDate.format(isSameDate ? 'LT' : 'LLLL')}
          </time>
        </DateRangeWrapper>
      ) : (
        <>
          <DateRangeWrapper>
            <DayOfWeek dateTime={end_date}>{endDate.format('ddd')}</DayOfWeek>
            <time dateTime={end_date}>{endDate.format('LL')}</time>
          </DateRangeWrapper>
          <DateRangeWrapper>
            <time dateTime={end_date}>{endDate.format('LT')}</time>
          </DateRangeWrapper>
        </>
      )}
    </DateRangeFormattedWrapper>
  );
};

const Metadata = styled.div`
  display: grid;
  grid-template-areas:
    'date'
    'address'
    'register';
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: space-between;
  row-gap: 16px;

  @media screen and (min-width: 992px) {
    grid-template-areas:
      'date register'
      'address register';
    grid-template-columns: 1fr auto;
  }

  a {
    grid-area: register;
  }
`;

const DateRangeList = styled.div`
  grid-area: date;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Address = styled.div`
  grid-area: address;

  display: flex;
  flex-direction: column;
`;

interface Props {
  event: App.Event;
  className?: string;
}

const Event: React.FC<Props> = function (props) {
  const { event, className } = props;
  const { title, content, date_ranges, address } = event;

  const eventVenues = useContext(EventVenuesContext) ?? [];

  const venue = eventVenues.find(venue => venue.title === address);

  return (
    <Section className={className}>
      <SectionDescription>{title}</SectionDescription>

      <EventContext.Provider value={event}>
        <Metadata>
          <DateRangeList>
            {date_ranges?.map((date, index) => (
              <DateRangeFormatted key={index} {...date} />
            ))}
          </DateRangeList>
          {venue && (
            <Address>
              <a href={venue.website}>{venue?.title}</a>
              <span>{venue.address}</span>
              <span>{venue.country}</span>
              <span>
                {venue.province} {venue.postal_code}
              </span>
            </Address>
          )}
          <RegisterButton />
        </Metadata>
        <MDX components={mdxComponents}>{content}</MDX>
      </EventContext.Provider>
    </Section>
  );
};

export default Event;
