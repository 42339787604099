import React from 'react';
import styled from 'styled-components';
import LinkAnchor from '../form-elements/LinkAnchor';

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.purple};
  display: grid;

  grid-auto-flow: column;
  grid-template-rows: 1fr 3fr 2fr 1fr;
  row-gap: 16px;

  padding: 16px;
  background: #ffffff;
`;

const Title = styled.h3`
  margin: 0;
`;

const Excerpt = styled.span``;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
`;

interface Props {
  wish: {
    title: string;
    excerpt: string;
    thumbnail: string;
    link: string;
  };
}

const Wish: React.FC<Props> = function (props) {
  const { wish } = props;
  const { title, excerpt, thumbnail, link } = wish;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Excerpt>{excerpt}</Excerpt>
      <Thumbnail src={thumbnail} />
      <LinkAnchor href={link}>Find out more</LinkAnchor>
    </Wrapper>
  );
};

export default Wish;
