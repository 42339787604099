import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';
import { device } from '../constants';

const Container = styled.div`
  display: flex;
  padding: ${props => props.theme.paddings.halfpd}px;
  border: 1px solid ${props => props.theme.colors.purple};
  background-color: #fff;
  flex-flow: row wrap-reverse;
`;

const ContainerSubHeading = styled.h3`
  color: ${props => props.theme.colors.purple};
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-size: 22px;
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;

interface FeaturedImgProps {
  bgImg?: string;
}
const TextContainer = styled.div<FeaturedImgProps>`
  margin-right: ${props => props.theme.paddings.halfpd}px;
  flex: 1 1 40%;
`;

const FeaturedImg = styled.div<FeaturedImgProps>`
  background-image: url('${props => props.bgImg ?? ''}');
  background-size: cover;
  background-position: center;
  padding-bottom: ${props => (props.bgImg ? '50%' : '0')};
  flex: ${props => (props.bgImg ? '0 1 100%' : '0 1 auto')};

  margin-bottom: 40px;
  
  @media screen and ${device.laptop} {
    flex: ${props => (props.bgImg ? '0 1 50%' : '0 1 auto')};
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
  font-size: 24px;

  &:not(.underlined-heading):after {
    height: 0px;
  }
`;
const Excerpt = styled.div`
  font-family: 'Open Sans';
`;

interface Props {
  subtitle?: string;
  title?: string;
  featuredImgSrc?: string;
  link?: string;
  className?: string;
}

const Card: React.FC<Props> = function (props) {
  const { subtitle, title, featuredImgSrc, link, className } = props;

  return (
    <Container className={className}>
      <TextContainer>
        {subtitle ? (
          <ContainerSubHeading>{subtitle}</ContainerSubHeading>
        ) : null}
        {title ? (
          <Title>{link ? <Link to={link}>{title}</Link> : title}</Title>
        ) : null}
        <Excerpt>{props.children}</Excerpt>
      </TextContainer>
      {link ? (
        <Link to={link}>
          <FeaturedImg bgImg={featuredImgSrc} />
        </Link>
      ) : (
        <FeaturedImg bgImg={featuredImgSrc} />
      )}
    </Container>
  );
};

export default Card;
