import React from 'react';
import CommitteesContext from '../context/CommitteesContext';
import EventCategoriesContext from '../context/EventCategoriesContext';
import SiteConfigContext from '../context/SiteConfigContext';
import UsersContext from '../context/UsersContext';
import EventsContext from '../context/EventsContext';
import { Data } from './CMSDataProvider';
import ResourcesContext from '../context/ResourcesContext';
import ResourceCategoriesContext from '../context/ResourceCategoriesContext';
import EventTagsContext from '../context/EventTagsContext';
import { assignWith } from 'lodash';
import JournalsContext from '../context/JournalsContext';
import EventVenuesContext from '../context/EventVenuesContext';

const CMSContextWrapper: React.FC<Data> = function (props) {
  const {
    children,
    siteCfg,
    users,
    committees,
    event_categories,
    events,
    resources,
    resource_categories,
    event_tags,
    event_venues,
    journals,
  } = props;

  // Merge all site config objects
  const siteCfgProcessed = siteCfg.edges
    .map(edge => edge.node.childMdx.frontmatter)
    .reduce((prev, current) => {
      return assignWith(prev, current, value =>
        value == null ? undefined : value
      );
    }, {});

  const usersProcessed = users.edges.map(
    edge => edge.node.childMdx.frontmatter
  );

  const committeesProcessed = committees.edges.map(edge => ({
    id: edge.node.id,
    ...edge.node.childMdx.frontmatter,
  }));

  const eventCategoriesProcessed = event_categories.edges.map(edge => ({
    id: edge.node.id,
    name: edge.node.name,
    ...edge.node.childMdx.frontmatter,
  }));

  const eventsProcessed = events.edges.map(edge => ({
    id: edge.node.id,
    name: edge.node.name,
    ...edge.node.childMdx.frontmatter,
  }));

  const eventTagsProcessed = event_tags.edges.map(edge => ({
    id: edge.node.id,
    name: edge.node.name,
    ...edge.node.childMdx.frontmatter,
  }));

  const eventVenuesProcessed = event_venues.edges.map(edge => ({
    id: edge.node.id,
    name: edge.node.name,
    ...edge.node.childMdx.frontmatter,
  }));

  const resourcesProcessed = resources.edges.map(edge => ({
    id: edge.node.id,
    ...edge.node.childMdx.frontmatter,
  }));

  const resourceCategoriesProcessed = resource_categories.edges.map(edge => ({
    id: edge.node.id,
    ...edge.node.childMdx.frontmatter,
  }));

  const journalsProcessed = journals.edges.map(edge => ({
    id: edge.node.id,
    ...edge.node.childMdx.frontmatter,
  }));

  return (
    <SiteConfigContext.Provider value={siteCfgProcessed}>
      <UsersContext.Provider value={usersProcessed}>
        <CommitteesContext.Provider value={committeesProcessed}>
          <EventCategoriesContext.Provider value={eventCategoriesProcessed}>
            <EventsContext.Provider value={eventsProcessed}>
              <EventTagsContext.Provider value={eventTagsProcessed}>
                <EventVenuesContext.Provider value={eventVenuesProcessed}>
                  <ResourcesContext.Provider value={resourcesProcessed}>
                    <ResourceCategoriesContext.Provider
                      value={resourceCategoriesProcessed}
                    >
                      <JournalsContext.Provider value={journalsProcessed}>
                        {children}
                      </JournalsContext.Provider>
                    </ResourceCategoriesContext.Provider>
                  </ResourcesContext.Provider>
                </EventVenuesContext.Provider>
              </EventTagsContext.Provider>
            </EventsContext.Provider>
          </EventCategoriesContext.Provider>
        </CommitteesContext.Provider>
      </UsersContext.Provider>
    </SiteConfigContext.Provider>
  );
};

export default CMSContextWrapper;
